import {
  Box,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import ActionTextField from "../components/ActionTextField";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Box sx={styles.main}>
      <Box sx={{ backgroundColor: "#7711E4" }}>
        <Box sx={styles.top} />
      </Box>
      <Box sx={styles.root} id="about">
        <Typography className="bottom-title-text" variant="h2">
          Want to be a Beta Tester?
        </Typography>
        <ActionTextField />
        <Box sx={{ marginTop: { md: "213px", xs: "81px" }, width: "100%" }}>
          <Grid
            container
            alignItems="flex-start"
            sx={{
              paddingX: { md: "10%", xs: 0 },
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              src={"/assets/logo.png"}
              sx={{ width: matches ? "60px" : "118px" }}
            />
            <Box sx={{ width: { md: "35%", xs: "75%" } }}>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Box
                // sx={{ marginRight: { md: "138px", xs: "15px", sm: "55px" } }}
                >
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "14px !important" }}
                    marginBottom="20px"
                  >
                    ABOUT US
                  </Typography>
                  <Typography
                    textTransform="uppercase"
                    //   sx={{ xs: "10px", md: "0.75rem" }}
                    variant="overline"
                    sx={{ fontWeight: 400, fontSize: "14px !important" }}
                    component="div"
                    marginBottom="5px"
                  >
                    Team
                  </Typography>
                  <Typography
                    textTransform="uppercase"
                    //   sx={{ xs: "10px", md: "0.75rem" }}
                    variant="overline"
                    sx={{ fontWeight: 400, fontSize: "14px !important" }}
                    component="div"
                  >
                    Our Story
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "14px !important" }}
                    marginBottom="20px"
                  >
                    SUPPORT
                  </Typography>
                  <Typography
                    textTransform="uppercase"
                    //   sx={{ xs: "10px", md: "0.75rem" }}
                    variant="overline"
                    sx={{ fontWeight: 400, fontSize: "14px !important" }}
                    component="div"
                    marginBottom="5px"
                  >
                    CONTACT US
                  </Typography>
                  <Typography
                    textTransform="uppercase"
                    //   sx={{ xs: "10px", md: "0.75rem" }}
                    variant="overline"
                    sx={{ fontWeight: 400, fontSize: "14px !important" }}
                    component="div"
                  >
                    HELP CENTER
                  </Typography>
                </Box>
              </Grid>
            </Box>
            <Box sx={styles.socialButtonsArea}>
              <Box
                component="img"
                src="/assets/Download.svg"
                sx={[styles.socialButton, { marginBottom: "10px" }]}
              />
              <Box
                component="img"
                src="/assets/Google.svg"
                sx={styles.socialButton}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.misika&pli=1",
                    "_blank"
                  );
                }}
              />
            </Box>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: { md: "150px", xs: "57px" },
            width: "100%",
            borderTop: "1px solid #FFFFFF",
            paddingTop: { md: "44px", xs: "19px" },
          }}
        >
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Typography variant="body2" component="p">
              All Rights Reserved MiSika 2023
            </Typography>
            <Box>
              <Grid container direction={matches ? "column" : "row"}>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{
                    marginRight: { md: "83px", xs: "0px" },
                    marginBottom: { md: "0px", xs: "18px" },
                  }}
                >
                  Terms and Conditions
                </Typography>
                <Link
                  variant="body2"
                  href="#/privacy"
                  color="common.white"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  Privacy and Policy
                </Link>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    padding: { md: "89px 10% 44px", xs: "45px 40px 21px" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#7711E4",
    borderRadius: { md: "0px 120px 0px 0px", xs: "0px 90px 0px 0px" },
  },
  socialButton: {
    height: { md: "41px", lg: "41px", xl: "41px", xs: "41px" },
    cursor: "pointer",
  },
  main: {
    backgroundColor: "white",
  },
  top: {
    backgroundColor: "white",
    height: "200px",
    marginTop: "-100px",
    borderRadius: { md: "0px 0px 0px 120px", xs: "0px 0px 0px 90px" },
  },
  bottom: {},
  socialButtonsArea: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { md: "column", xs: "row" },
    width: { md: "auto", xs: "100%" },
    marginTop: { md: 0, xs: "30px" },
  },
};

export default Footer;
