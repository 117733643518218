import {
  ChevronLeft,
  ExpandLess,
  ExpandMore,
  StyleSharp,
} from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Grid,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { STYLES } from "../../../types/common";

interface FAQProps {
  faq: { name: string; body: string };
}

const FAQ: React.FC<FAQProps> = ({ faq }) => {
  const [open, setOpen] = useState(false);
  console.log({ open });
  return (
    <Box sx={{ width: { md: "60%", xs: "100%" } }}>
      <Box
        sx={{
          padding: "1px",
          background:
            "linear-gradient(178deg, #FFE000 -16.68%, #8F27FF 78.71%)",
          marginY: { md: "15px", xs: "5px" },
          borderRadius: { md: "10px", xs: "5px" },
        }}
      >
        <ButtonBase
          sx={[styles.box, { backgroundColor: open ? "#8F27FF" : "white" }]}
          onClick={() => setOpen(!open)}
        >
          <Grid container justifyContent="space-between" alignItems={"center"}>
            <Box width={"90%"}>
              <Typography
                // className={open ? "" : "faq-text"}
                sx={[styles.faqText, open && { color: "white" }]}
              >
                {faq.name}
              </Typography>
            </Box>
            <Box
              // component="img"
              // src={"/assets/dropdown.svg"}
              sx={[
                styles.icon,
                {
                  transition: "transform 0.3s ease-in-out",
                  transform: open ? `rotate(180deg)` : "",
                  backgroundColor: open ? "white" : "#8F27FF",
                  color: open ? "#8F27FF" : "white",
                },
              ]}
            >
              {/* {open ? ( */}
              <ExpandLess color="inherit" />
              {/* // ) : (
              //   <ExpandMore color="inherit" />
              // )} */}
            </Box>
          </Grid>
        </ButtonBase>
      </Box>
      {open && (
        <Box sx={{ height: "120px", padding: "20px" }}>
          <Typography color="grey.500" fontWeight="400">
            {faq.body}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

// : SxProps<Theme>
const styles = {
  box: {
    padding: { md: "0px 40px", xs: "0px 15px" },
    borderRadius: { md: "10px", xs: "5px" },
    height: { md: "60px", xs: "45px" },
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid",

    borderImageSource:
      "linear-gradient(145.56deg, #FFE000 -16.68%, #8F27FF 78.71%)",
  },
  faqText: {
    color: "#2F2E41CC",
    fontSize: { md: "16px", xs: "14px" },
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "left",
    fontWeight: 500,
  },
  icon: {
    height: "25px",
    width: "25px",
    borderRadius: "5px",
  },
};

export default FAQ;
