import { Box, Typography } from "@mui/material";
import React from "react";
import Feature from "../components/Feature";

const features = [
  {
    title: "Access all your accounts in one place.",
    body: "Add and manage all your card, bank and mobile money accounts in one place",
    logo: "/assets/accounts.png",
    color: "rgba(66, 202, 232, 0.11)",
  },
  {
    title: "Auto-Transaction Tracker",
    body: "Create a personalised budget and track all your transactions no matter where you spend",
    logo: "/assets/transactions.png",
    color: "#FFE0001C",
    reverse: true,
  },
  {
    title: "Auto Save",
    body: "Save automatically daily, weekly or monthly in just a few clicks.",
    logo: "/assets/save.png",
    color: "#EDDDFF",
  },
];

interface FeaturesProps {}

const Features: React.FC<FeaturesProps> = ({}) => {
  return (
    <Box sx={styles.root} id="features">
      <Typography className="title-text" variant="h2">
        Explore the APP Features
      </Typography>
      <Box>
        {features.map((f, index) => (
          <Feature feature={f} key={index} />
        ))}
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    backgroundColor: "common.white",
    padding: { md: "100px 200px 168px", xs: "50px 40px" },
    borderRadius: { md: "0px 120px 0px 0px", xs: "0px 90px 0px 0px" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default Features;
