import { Box, Typography } from "@mui/material";
import React from "react";
import FAQ from "../components/FAQ";
import Feature from "../components/Feature";

// const faqs = [
//   "Is your payment platform secure?",
//   "How do I change my account email?",
//   "Can I add more than one Bank Account?",
//   "How do I change my account email?",
//   "How do I change my account email?",
//   "How do I change my account email?",
//   "How do I change my account email?",
//   "How do I change my account email?",
// ];

const faqs = [
  {
    name: "What is MiSika?",
    body: "MiSika is currently the MVP version of a one-stop personal financial assistant app. MiSika currently allows users to manage their cash wallets and offers features like budgeting and uploading account statements from third parties for analytics.",
  },
  {
    name: "Can I link my bank accounts or mobile money accounts in the MVP version?",
    body: "In the MVP, you can only operate with cash wallets. However, we'll release updates that integrate bank and mobile money accounts soon.",
  },
  {
    name: "How do I create a budget using the app?",
    body: "To create a budget, click to add a budget in the budgeting section of the app and follow the simple steps to set your spending limits for different categories.",
  },
  {
    name: "How do I change my account limit?",
    body: "Eaque ad qui numquam reprehenderit molestias amet. Nam pariatur natus soluta unde a reprehenderit placeat. Tempore ad labore sunt doloribus soluta dolorum.",
  },
  {
    name: "Can I upload account statements from other financial institutions for analysis?",
    body: "Yes, you can upload account statements from other financial institutions to get a comprehensive analysis of your financial behavior.",
  },
  {
    name: "How do I track my expenses in the MVP version?",
    body: "You can easily track your expenses by recording your cash transactions in the app. In future updates, you'll be able to track expenses automatically from linked accounts as well.",
  },
  {
    name: "Is the app available on both Android and iOS devices?",
    body: "Yes, our app will be available on both Android and iOS platforms.",
  },
  {
    name: "Is my financial data secure within the app?",
    body: "Yes, we prioritize the security of your financial data. We use robust encryption and security protocols to keep your information safe.",
  },
  {
    name: "Can I share my budget or expense reports with others using the app?",
    body: "Currently, the MVP version focuses on personal finance management. However, we will add sharing features in future updates.",
  },
  {
    name: "Is there customer support available if I have questions or encounter issues?",
    body: "Yes, we offer 24/7 assistance to customers with any inquiries or problems you may encounter while using the app. You can reach out to us through our support channels",
  },
];

interface FeaturesProps {}

const Features: React.FC<FeaturesProps> = ({}) => {
  return (
    <Box sx={styles.main}>
      <Box sx={styles.root} id="faqs">
        <Typography
          color="grey.600"
          variant="h2"
          marginBottom={"30px"}
          marginTop={"40px"}
          className="title-text"
        >
          FAQs
        </Typography>
        {faqs.map((faq, index) => (
          <FAQ faq={faq} key={index} />
        ))}
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    backgroundColor: "common.white",
    padding: { md: "100px 200px", xs: "50px 40px" },
    // borderRadius: { md: "0px 0px 30px 30px", xs: "0px 0px 15px 15px" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  main: {
    backgroundColor: "#fff0c2",
  },
  top: {
    backgroundColor: "#fff0c2",
    height: "200px",
    marginTop: "-100px",
    borderRadius: { md: "0px 0px 0px 120px", xs: "0px 0px 0px 90px" },
  },
};

export default Features;
