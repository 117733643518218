import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

interface GetStartedProps {}

const getStartedItems = [
  {
    title: "Download the MiSika App",
    body: "Get the MiSika App  available on iOS and Android",
    logo: "/assets/frame-3.svg",
  },
  {
    title: "Add your account(s)",
    body: "Simply sign up and add an account(s)",
    logo: "/assets/frame-2.svg",
  },
  {
    title: "Start adding your transactions",
    body: "Create  a personalised budget and start adding your transactions",
    logo: "/assets/frame-1.svg",
  },
];

const GetStarted: React.FC<GetStartedProps> = ({}) => {
  const matches = useMediaQuery("(max-width:767px)");
  return (
    <Box sx={styles.main}>
      <Box sx={{ backgroundColor: "#fff0c2" }}>
        <Box sx={styles.top} />
      </Box>
      <Box sx={styles.root} id="getstarted">
        <Typography className="title-text" variant="h2">
          Get started in these easy steps
        </Typography>
        <Box
          sx={{
            width: { md: "80%", xs: "80%" },
            paddingTop: { md: "55px", xs: "45px" },
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { md: "row", sm: "column", xs: "column" },
          }}
        >
          {getStartedItems.map((item, index) => (
            <Box
              sx={{
                padding: "1px",
                background:
                  "linear-gradient(145.56deg, #FFE000 -16.68%, #8F27FF 78.71%)" /* Change colors as needed */,

                marginBottom: { md: 0, sm: "20px", xs: "20px" },
                borderRadius:
                  "20px" /* Adjust the last value for the border width */,
                // backgroundClip: "content-box",
              }}
            >
              <Box sx={{ backgroundColor: "#fff0c2", borderRadius: "20px" }}>
                <Box
                  sx={{
                    width: "350px",
                    height: "230px",
                    position: "relative",
                    background:
                      "linear-gradient(145.56deg, rgba(255, 224, 0, 0.1) -16.68%, rgba(143, 39, 255, 0.1) 78.71%)",
                    borderRadius: "20px",
                    padding: "30px 12px",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // borderImageSlice: "1",
                    overflow: "hidden",
                  }}
                  key={index}
                >
                  <Box
                    component="img"
                    src={item.logo}
                    sx={{
                      width: { md: "53px", xs: "53px" },
                    }}
                  />
                  <Box>
                    <Typography
                      sx={{
                        margin: "10px 0px",
                        fontSize: "20px",
                      }}
                      variant="h4"
                      textAlign="center"
                      color="#4E4C6A"
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      textAlign="center"
                      variant="caption"
                      color="grey.500"
                      component="h4"
                      fontWeight={"500"}
                      lineHeight={1.8}
                    >
                      {item.body}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box style={styles.bottom} />
    </Box>
  );
};

const styles = {
  root: {
    backgroundColor: "#fff0c2",
    padding: { md: "90px 0px 100px", xs: "50px 0px 27px" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: { md: "0px 120px 0px 0px", xs: "0px 90px 0px 0px" },
  },
  socialButton: {
    height: { md: "55px", lg: "55px", xl: "80px", xs: "48px" },
  },
  main: {
    backgroundColor: "white",
  },
  top: {
    backgroundColor: "white",
    height: { md: "200px", xs: "120px" },
    marginTop: "-100px",
    borderRadius: { md: "0px 0px 0px 120px", xs: "0px 0px 0px 90px" },
  },
  bottom: {},
};

export default GetStarted;
