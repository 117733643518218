import {
  Box,
  Container,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Dots from "../components/Dots";
import Navbar from "../components/Navbar";

interface LandingProps {}

const Landing: React.FC<LandingProps> = ({}) => {
  const matches = useMediaQuery("(max-width:767px)");
  return (
    <Box sx={styles.mainBack}>
      <Box sx={styles.main} id="home" bgcolor="primary.main">
        {/* <Box>
          <Box
            component="img"
            src="/assets/Subtract.svg"
            sx={styles.topCurve}
          />
        </Box> */}
        <Navbar />
        <Box sx={{ height: { md: "63px", xs: "43px" }, zIndex: 5 }} />
        <Box sx={styles.content}>
          <Box sx={styles.leftSide}>
            <Typography
              fontWeight={"800"}
              variant="h1"
              component="h1"
              gutterBottom
            >
              {/* Track all your transactions in one place? */}
              Your{" "}
              <Typography
                fontWeight={"800"}
                variant="h1"
                component="small"
                className="logo-text"
              >
                One-Stop
              </Typography>{" "}
              Financial Assistant
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              fontWeight={400}
              textTransform={"none"}
              lineHeight={25 / 16}
            >
              Track all your transactions in one place, and get in-depth
              analytics to manage your money.
            </Typography>
            <Box sx={styles.socialButtonsArea}>
              <Box
                component="img"
                src="/assets/Download.svg"
                sx={[
                  styles.socialButton,
                  { marginRight: { md: "5%", xs: "0" } },
                ]}
              />
              <Box
                component="img"
                src="/assets/Google.svg"
                sx={styles.socialButton}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.misika&pli=1",
                    "_blank"
                  );
                }}
              />
            </Box>
          </Box>
          <Box width={matches ? "100%" : "45%"} sx={styles.imageContainer}>
            <Box
              component="img"
              src={
                matches ? "/assets/top-left-small.svg" : "/assets/top-left.svg"
              }
              sx={{ zIndex: 5 }}
            />
          </Box>
        </Box>

        <Dots />
      </Box>
    </Box>
  );
};

const styles = {
  main: {
    height: { md: "80vh", xs: "auto" },
    minHeight: { md: "638px", xs: "800px" },
    padding: { md: "40px 40px 0px", xs: "30px 20px 0px" },
    display: "flex",
    flexDirection: "column",
    position: "relative",
    // borderRadius: "0px 0px 0px 120px",
    borderRadius: { md: "0px 0px 0px 120px", xs: "0px 0px 0px 90px" },
  },
  mainBack: {
    backgroundColor: "white",
  },
  content: {
    width: { lg: "85%", xl: "80%", md: "90%", xs: "100%" },
    margin: "auto",
    display: "flex",
    alignItems: "center",
    flex: "1",
    padding: { md: "0px 0px 50px", xs: "50px 0px 50px" },
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: "space-between",
    zIndex: 5,
  },
  leftSide: {
    width: { md: "45%", lg: "45%", xs: "100%" },
    marginTop: { md: "20px", xs: "0px" },
  },
  socialButtonsArea: {
    display: "flex",
    justifyContent: { xs: "space-between", md: "flex-start" },
    margin: { md: "40px 0px 0px", xs: "40px auto 20px" },
    width: { md: "95%", xs: "100%" },
  },
  socialButton: {
    height: { md: "auto", lg: "auto", xl: "auto", xs: "auto" },
    width: { md: "auto", xs: "47%" },
    cursor: "pointer",
  },
  image: {
    height: { md: "100%", xs: "400px" },
    width: { md: "75%", xs: "100%" },
    marginRight: { md: "-15%", xs: "0%" },
    backgroundImage: "url('/assets/top-left.svg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  topCurve: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10000,
    width: "100%",
    display: { md: "none", xs: "block" },
  },
  imageContainer: {
    display: " flex",
    alignItems: " center",
    justifyContent: " center",
  },
};

export default Landing;
