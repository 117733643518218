import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import Slider from "../components/Slider";
import Testimonial from "../components/Testimonial";

interface WhatPeopleSayProps {}

const WhatPeopleSay: React.FC<WhatPeopleSayProps> = ({}) => {
  const matches = useMediaQuery("(max-width:767px)");
  return (
    <Box sx={styles.main}>
      <Box sx={{ backgroundColor: "white" }}>
        <Box sx={styles.top} />
      </Box>
      <Box sx={styles.root} id="testimonials">
        {/* <Typography className="title-text" variant="h2">
          What People Say
        </Typography> */}
        {/* <Box sx={{ width: "100%", paddingTop: { md: "55px", xs: "15px" } }}>
        <Slider items={Array.from(Array(10).keys())} key={0} seconds={4000} />
        {!matches && (
          <Slider items={Array.from(Array(10).keys())} key={1} seconds={5000} />
        )}
      </Box> */}
        <Box sx={styles.actionArea}>
          <Grid
            container
            direction={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box sx={{ width: { md: "70%", xs: "100%", sm: "100%" } }}>
              <Typography className="action-title" variant="h2">
                Keep track of your transactions with ease.
              </Typography>
              <Box sx={{ width: { md: "100%" } }} marginTop="10px">
                <Typography
                  color="grey.600"
                  variant="body2"
                  fontWeight={"400"}
                  sx={styles.body}
                >
                  Available on Apple App Store and Google Play Store
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { md: "auto", xs: "300px", sm: "300px" },
                marginX: "auto",
                marginTop: { xs: "30px", md: "0px" },
                margin: { md: "0", sm: "auto" },
                display: "flex",
                flexDirection: { md: "column", xs: "row" },
                justifyContent: "space-between",
              }}
            >
              <Box
                component="img"
                src="/assets/Download.svg"
                sx={[
                  styles.socialButton,
                  // { marginRight: { md: "24px", sm: "5%" } },
                ]}
              />
              <Box
                component="img"
                src="/assets/Google.svg"
                sx={styles.socialButton}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.misika&pli=1",
                    "_blank"
                  );
                }}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    backgroundColor: "common.white",
    padding: { md: "100px 0px 0px", xs: "50px 0px 0px" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: { md: "0px 120px 0px 0px", xs: "0px 90px 0px 0px" },
  },
  socialButton: {
    height: { md: "auto", lg: "auto", xl: "auto", xs: "41px", sm: "41px" },
    cursor: "pointer",
    marginBottom: "15px",
  },
  main: {
    backgroundColor: "#fff0c2",
  },
  top: {
    backgroundColor: "#fff0c2",
    height: { md: "200px", xs: "120px" },
    marginTop: "-100px",
    borderRadius: { md: "0px 0px 0px 120px", xs: "0px 0px 0px 90px" },
  },
  actionArea: {
    margin: { md: "90px auto 0px", xs: "30px auto 0px" },
    width: { md: "85%", lg: "85%", sm: "90%", xs: "90%", xl: "70%" },
    backgroundColor: "#fff0c2",
    padding: { md: "72px 112px", xs: "20px 20px" },
    borderRadius: { md: "20px", xs: "10px" },
    height: { md: "271px", xs: "223px" },
  },
  body: {
    fontSize: { md: "18px", xs: "16px" },
    marginTop: { md: "0px", xs: "16.5px" },
  },
};

export default WhatPeopleSay;
