import { Box, Container, Typography } from "@mui/material";
import React from "react";
import FAQs from "./sections/FAQs";
import Features from "./sections/Features";
import Footer from "./sections/Footer";
import GetStarted from "./sections/GetStarted";
import Landing from "./sections/Landing";
import WhatPeopleSay from "./sections/WhatPeopleSay";

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  return (
    <Box bgcolor="primary.main" component="main" color="common.white" id="home">
      <Landing />
      <Features />
      <GetStarted />
      <WhatPeopleSay />
      <FAQs />
      <Footer />
    </Box>
  );
};

export default Home;
