import { Close } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const links = [
  { name: "FEATURES", to: "#features" },
  { name: "HOW IT WORKS", to: "#getstarted" },
  // { name: "TESTIMONIALS", to: "#testimonials" },
  { name: "FAQs", to: "#faqs" },
  { name: "ABOUT US", to: "#about" },
];

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "transparent",
    boxShadow: "none",
  },
  root: {
    background: "#330069E5",
  },
  closeButton: {
    float: "right",
    margin: "60px 20px 20px",
    zIndex: 20000,
  },
});

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = ({}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:767px)");
  const [selected, setSelected] = useState("");
  const [state, setState] = React.useState({
    right: false,
  });

  const [offset, setOffset] = useState(0);

  const toggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  // Empty dependency array means this effect runs once on mount

  // Define a threshold for when to change background color (e.g., 100px)
  const threshold = 638;

  // Calculate background color based on scroll position
  let logoUrl =
    offset > threshold || matches ? "/assets/logo.png" : "/assets/logo-nav.png";

  const onScroll = () => {
    let read = document.body.scrollTop || document.documentElement.scrollTop;

    setOffset(read);
  };

  useEffect(() => {
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, true);
    return () => window.removeEventListener("scroll", onScroll, true);
  }, []);
  return (
    <Box component="nav" sx={styles.navBar}>
      <Link
        onClick={(e) => {
          let hero = document.querySelector("#home");
          e.preventDefault(); // Stop Page Reloading
          hero && hero.scrollIntoView();
        }}
      >
        <Box
          component="img"
          src={logoUrl}
          sx={{ width: matches ? "76px" : "118px" }}
        />
      </Link>
      {matches ? (
        <>
          <ButtonBase onClick={toggleDrawer("right", true)}>
            <Box component="img" src="/assets/ham.svg" />
          </ButtonBase>
        </>
      ) : (
        <>
          {links.map((link, index) => (
            <Link
              key={index}
              variant="subtitle1"
              color={"common.white"}
              textTransform="inherit"
              fontWeight={400}
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                let hero = document.querySelector(link.to);
                e.preventDefault(); // Stop Page Reloading
                hero && hero.scrollIntoView();
              }}
            >
              {link.name}
            </Link>
          ))}
        </>
      )}

      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        classes={{ paper: classes.paper, root: classes.root }}
        hideBackdrop
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer("right", false)}
          onKeyDown={toggleDrawer("right", false)}
        >
          <ButtonBase
            onClick={toggleDrawer("right", false)}
            classes={{ root: classes.closeButton }}
          >
            <Close sx={{ color: "common.white", fontSize: "40px" }} />
          </ButtonBase>
          <List>
            {links.map((link, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <Link
                    key={index}
                    color={"common.white"}
                    // href={link.to}
                    sx={{
                      textDecoration: "none !important",
                      fontSize: "24px",
                      marginBottom: "20px",
                      fontWeight: 400,
                    }}
                    onClick={(e) => {
                      let hero = document.querySelector(link.to);
                      console.log({ hero });
                      e.preventDefault(); // Stop Page Reloading

                      setTimeout(function () {
                        hero &&
                          hero.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                      }, 100);
                    }}
                  >
                    {link.name}
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

const styles = {
  navBar: {
    display: "flex",
    width: { lg: "70%", md: "calc(100% - 80px)", xs: "calc(100% - 40px)" },
    // border: { md: "none", xs: "2px solid #FFFFFF" },
    margin: "auto",
    // backdropFilter: "blur(2px)",
    borderRadius: "100px",
    background:
      "linear-gradient(180deg, rgba(158, 68, 255, 0.7) 0%, rgba(123, 0, 255, 0.7) 100%)",
    alignItems: "center",
    padding: { md: "10px 60px", xs: "10px 18px" },
    justifyContent: "space-between",
    position: "fixed",
    // background: "#7711E4CC",
    left: { lg: "15%", xs: "auto" },
    zIndex: 50,
    height: { md: "68px", xs: "52px" },
  },
};

export default Navbar;
