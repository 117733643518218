import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import Privacy from "../pages/Privacy";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "#/home",
      element: <Home />,
    },
    {
      path: "*",
      element: <Home />,
    },
  ]);

  return routes;
}
