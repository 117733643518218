import { Box, colors } from "@mui/material";
import React from "react";

interface PrivacyProps {}

const Privacy: React.FC<PrivacyProps> = ({}) => {
  return (
    <>
      <Box sx={styles.bgimg}>
        <Box className="topleft">
          <Box component="img" src={"/assets/logo_violet.png"} />
        </Box>
        <Box>
          <Box>
            <p>
              Our Privacy Policy governs your visit to{" "}
              <a href="https://misika.ai/privacy">https://misika.ai/privacy</a>{" "}
              and misika mobile application, and explains how we collect,
              safeguard and disclose information that results from your use of
              our Service.
            </p>
            <p>
              We use your data to provide and improve Service. By using Service,
              you agree to the collection and use of information in accordance
              with this policy. Unless otherwise defined in this Privacy Policy,
              the terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions.
            </p>
            <p>
              Our Terms and Conditions (“Terms”) govern all use of our Service
              and together with the Privacy Policy constitutes your agreement
              with us (“agreement”).
            </p>
            <p></p>
          </Box>
          <Box>
            <p className="card-text"></p>
            <p>MiSika uses the collected data for various purposes:</p>
            <ul>
              ​<li>(a) to provide and maintain our Service;</li>
              <li>(b) to notify you about changes to our Service; </li>
              <li>
                (c) to allow you to participate in interactive features of our
                Service when you choose to do so;{" "}
              </li>
              <li>(d) to provide customer support; </li>
              <li>
                (e) to gather analysis or valuable information so that we can
                improve our Service;{" "}
              </li>
              <li>(f) to monitor the usage of our Service;</li>
              <li>(g) to detect, prevent and address technical issues;</li>
              <li>
                (h) to fulfill any other purpose for which you provide it;
              </li>
              <li>
                (i) to carry out our obligations and enforce our rights arising
                from any contracts entered into between you and us, including
                for billing and collection;
              </li>
              <li>
                (j) to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </li>
              <li>
                (k) to provide you with news, special offers and general
                information about other goods, services and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless you have opted not to receive such
                information;
              </li>
              <li>
                (l) in any other way we may describe when you provide the
                information;
              </li>
              <li> (m) for any other purpose with your consent. </li>
              <p></p>
              <p></p>
            </ul>
          </Box>
          <Box>
            <h3><b  style={{color: '#7711E4'}}>HOW TO DELETE YOUR ACCOUNT</b></h3>

            Send an email with the subject <b>Delete my account</b> to <b style={{color: '#7711E4'}}>tech@misika.ai.</b>  <br/>
            Include the following details in your email:
            1. The email address registered to misika app.<br/>
            2. The amount in your last income transaction.<br/>
            3. The amount in your last expense transaction.<br/>
            4. The total wallet balance at the moment of sending this email.

            <br/><br />
            Once this email is received, all of your details will be deleted in 2 days.
            <br/><br/>
          </Box>
        </Box>
        <Box className="bottomleft"></Box>
      </Box>
    </>
  );
};

const styles = {
  bgimg: {
    /* background-image: url('/w3images/forestbridge.jpg'); */
    height: "80%",
    backgroundPosition: "center",
    position: "relative" as string,
    color: "black",
    fontFamily: "'Courier New', Courier, monospace",
    fontSize: "15px",
  },
  topleft: {
    position: "absolute",
    top: 0,
    left: "16px",
  },
  bottomleft: {
    position: "absolute",
    bottom: 0,
    left: "16px",
  },
  middle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  hr: {
    margin: "auto",
    width: "40%",
  },
};

export default Privacy;
