import * as React from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./App.scss";
import Router from "./routes";

export default function App() {
  return (
    <HashRouter>
      <Router />
    </HashRouter>
  );
}
