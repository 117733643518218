import EastIcon from "@mui/icons-material/East";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

interface ActionTextFieldProps {}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    backgroundColor: theme.palette.common.white,
    padding: "7px 16px 7px 50px",
    borderRadius: "40px",
    height: "55px",
    width: "55%",
    margin: "auto",
    fontSize: "20px",
    fontWeight: "500",
    "&::placeholder": {
      color: "#9A9A9A",
    },
    "&:hover": { backgroundColor: theme.palette.common.white },
    "&:focus": { backgroundColor: theme.palette.common.white },
    [theme.breakpoints.down("sm")]: {
      width: "98%",
      fontSize: "12px",
      height: "40px",
      padding: "7px 16px 7px 23px",
    },
  },
  forwardButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: "250px",
    borderRadius: "40px",
    height: "45px",
    "&:hover": { backgroundColor: theme.palette.primary.main },
    "&:focus": { backgroundColor: theme.palette.primary.main },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      height: "33px",
      width: "47px",
    },
  },
}));

const ActionTextField: React.FC<ActionTextFieldProps> = ({}) => {
  const classes = useStyles();

  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Box sx={{ width: "100%" }}>
      <FormControl
        sx={{ m: 1, width: "100%", marginTop: { md: "32px", xs: "16px" } }}
        variant="filled"
      >
        <FilledInput
          id="filled-adornment-password"
          type={"text"}
          classes={{ root: classes.input }}
          disableUnderline={true}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          placeholder="Enter email address here"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                classes={{
                  root: classes.forwardButton,
                }}
                aria-label="toggle password visibility"
                edge="end"
              >
                {/* <EastIcon
                color="inherit"
                fontSize={matches ? "medium" : "large"}
                sx={{ stroke: "#ffffff", strokeWidth: 1 }}
              /> */}
                <Typography
                  color="common.white"
                  variant="body2"
                  fontWeight={"500"}
                >
                  Join For Free
                </Typography>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Button
        sx={{
          width: "98%",
          padding: "14px 33px",
          border: "1px solid #FFFFFF",
          borderRadius: "40px",
          margin: "10px 3% 0px",
          height: "40px",
          display: { sm: "inline-block", md: "none" },
        }}
      >
        <Typography color="common.white" variant="body2" fontWeight={"500"}>
          Join For Free
        </Typography>
      </Button>
    </Box>
  );
};

export default ActionTextField;
