import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

interface FEATURE {
  title: string;
  body: string;
  logo: string;
  color: string;
  reverse?: boolean;
}
interface FeatureProps {
  feature: FEATURE;
}

const Feature: React.FC<FeatureProps> = ({ feature }) => {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{
        marginTop: { md: "100px", xs: "20px" },
        width: { md: "847px", xs: "auto" },
        marginX: "auto",
      }}
      direction={
        matches ? "column-reverse" : feature.reverse ? "row-reverse" : "row"
      }
    >
      <Grid item xs={6} alignItems="center">
        <Box
          sx={[
            {
              // paddingRight: { md: "50px", xs: "0px" },
              paddingTop: { md: "0px", xs: "20px" },
              // paddingLeft: { md: "100px", x: "0px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              width: { md: "330px", xs: "auto" },
              textAlign: { md: "left", xs: "center" },
            },
            !matches && feature.reverse
              ? {
                  marginLeft: "auto",
                  // paddingRight: { md: "0px", xs: "0px" },
                  // paddingLeft: { md: "150px", x: "0px" },
                }
              : {},
          ]}
        >
          <Typography
            sx={{
              paddingRight: { md: "0px", xs: "0px" },
              marginBottom: { md: "20px", xs: "10px" },
            }}
            variant="h3"
            color="#4E4C6A"
          >
            {feature.title}
          </Typography>
          <Typography variant="caption" color="grey.500" fontWeight={"400"}>
            {feature.body}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            height: { md: "564px", xs: "336px" },
            backgroundColor: "white",
            // backgroundImage: `url('${feature.logo}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            padding: "0px 0px",
            position: "relative",
          }}
        >
          <Box
            component="img"
            src={feature.logo}
            sx={{ height: { md: "509px", xs: "316px", zIndex: 2 } }}
          />
          <Box
            sx={{
              position: "absolute",
              background: feature.color,
              width: { md: "425px", xs: "270px" },
              borderRadius: { md: "50px", xs: "30px" },
              bottom: "0",
              height: "65%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Feature;
