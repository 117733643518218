import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as animationData from "./json/animation.json";

interface DotsProps {}

const Dot = ({ index }: { index: number }) => {
  // Empty dependency array ensures useEffect runs only once

  return <Box key={index} className={`moving-object eclipse-${index + 1}`} />;
};

const Dots: React.FC<DotsProps> = ({}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {Array.from({ length: 12 }, (_, i) => i).map((index) => (
        <Dot key={index} index={index} />
      ))}
      {/* <Lottie
        options={defaultOptions}
        height={500}
        width={400}
        // isStopped={this.state.isStopped}
        // isPaused={this.state.isPaused}
      /> */}
    </>
  );
};

export default Dots;
